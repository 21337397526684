import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import CustomDialog from 'src/components/custom-dialog/custom-dialog';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import Panel from 'src/components/panel/panel';
import { Body } from 'src/components/typography/typography';
import { generateRunGroupId } from 'src/components/usta-rankings/helpers';
import { RankListJobs } from 'src/graphql-types/RankListJobs';
import { generateRanklistName } from 'src/utils/generate-ranklist-name/generate-ranklist-name';
import ROUTES from 'src/utils/routes';

import * as styles from './rankings-processing.module.less';

interface RankingsProcessing {
  ranklistJobs?: RankListJobs['rankListJobs'];
  action: () => void;
  setChecked: Dispatch<SetStateAction<{ [key: string]: boolean }>>;
}

enum States {
  PROCESSING,
  COMPLETE_SUCCESS,
  COMPLETE_PARTIAL_SUCCESS,
  COMPLETE_FAILED,
}

interface StateMap {
  title: string;
  description: string;
  buttonText: string;
  buttonAction: () => void;
  getStatus: (curr: number, total: number) => ReactNode;
  imgSrc: string;
}

export const RankingsProcessing = ({ ranklistJobs, action, setChecked }: RankingsProcessing) => {
  const { t } = useTranslation();
  const [state, setState] = useState<States>(States.PROCESSING);
  const [errorDialog, setErrorDialog] = useState<boolean>(false);

  const numOfTotalJobs = ranklistJobs?.length ?? 0;
  const successfullyCompletedJobs = ranklistJobs?.filter((job) => job?.jobErrors?.length === 0);
  const completedJobs = ranklistJobs?.filter((job) => job?.jobComplete);
  const isAllJobsCompleted = ranklistJobs?.every((job) => job?.jobComplete);
  const jobsCompletedWithErrors = ranklistJobs?.filter((job) => job?.jobComplete && job?.jobErrors?.length !== 0);

  const allJobsCompletedWithErrors = isAllJobsCompleted && jobsCompletedWithErrors?.length === completedJobs?.length;

  const STATES_MAP: Record<States, StateMap> = {
    [States.PROCESSING]: {
      title: t('processing runs'),
      description: t('processing runs description'),
      buttonText: t('cancel processing'),
      buttonAction: () => {},
      getStatus: () => (
        <p className={styles.processingStatus}>
          {t(`processing ranking runs`, {
            current: completedJobs?.length ?? 0,
            total: numOfTotalJobs,
          })}
        </p>
      ),
      imgSrc: 'ranklists-processing',
    },
    [States.COMPLETE_SUCCESS]: {
      title: t('processing complete'),
      description: t('processing complete description'),
      buttonText: t('close'),
      buttonAction: action,
      getStatus: () => (
        <p className={styles.completeStatus}>
          {t('successfully processed description', {
            amount: completedJobs?.length,
          })}
        </p>
      ),
      imgSrc: 'ranklists-success',
    },
    [States.COMPLETE_PARTIAL_SUCCESS]: {
      title: t('processing complete'),
      description: t('runs created with errros'),
      buttonText: t('close'),
      buttonAction: action,
      getStatus: (current, total) => (
        <p className={styles.completeStatus}>{t(`successfully processed ranking runs`, { current, total })}</p>
      ),
      imgSrc: 'ranklists-success',
    },
    [States.COMPLETE_FAILED]: {
      title: t('processing was unsuccessful'),
      description: t('unsuccessful processing description'),
      buttonText: t('close'),
      buttonAction: action,
      getStatus: (current, total) => (
        <p className={styles.unsuccessfulCompleteStatus}>
          {t('unsuccessfully processed description', {
            amount: current,
            total,
          })}
        </p>
      ),
      imgSrc: 'ranklists-unsuccessful',
    },
  };

  const currentState = STATES_MAP[state];

  useEffect(() => {
    // Still processing jobs
    if (!isAllJobsCompleted) return;

    if (ranklistJobs?.length === jobsCompletedWithErrors?.length) {
      // All jobs failed
      setState(States.COMPLETE_FAILED);
    } else if (jobsCompletedWithErrors?.length) {
      // Some jobs failed
      setState(States.COMPLETE_PARTIAL_SUCCESS);
    } else if (!jobsCompletedWithErrors?.length) {
      // No jobs failed
      setState(States.COMPLETE_SUCCESS);
    }
  }, [ranklistJobs]);

  const handleReselectAndClose = () => {
    const ranklistJobInputs =
      ranklistJobs?.filter((job) => job?.jobErrors?.length !== 0)?.map((job) => job?.input) ?? [];
    const ids = ranklistJobInputs.map((input: any) =>
      generateRunGroupId({
        playerType: input.playerType,
        listType: input.listType,
        ageRestriction: input.ageRestriction,
        matchFormat: input.matchFormat,
        matchFormatType: input.matchFormatType,
        familyCategory: input.familyCategory,
        playerLevel: input.playerLevel,
        gender: input.gender,
        genderModifier: input.genderModifier,
        divisionType: input.divisionType,
        region: input.region,
      }),
    );

    const checkedIds = ids.reduce((prev, curr) => ({ ...prev, [curr]: true }), {});
    setChecked(checkedIds);
    navigate(ROUTES.RANKINGS);
    setErrorDialog(false);
  };

  return (
    <Panel spacing={{ margins: { lg: 'bottom' } }}>
      <Grid container className={styles.container}>
        <img src={require(`src/images/${currentState.imgSrc}.gif`).default} className={styles.statusImg} />
        <Grid item>
          <Grid container item direction="column">
            <h2 className={styles.heading}>{currentState.title}</h2>
            <Body size="md" light>
              {currentState.description}
            </Body>
            {ranklistJobs ? currentState.getStatus(successfullyCompletedJobs?.length ?? 0, numOfTotalJobs) : null}
            {isAllJobsCompleted && jobsCompletedWithErrors?.length !== 0 && !allJobsCompletedWithErrors && (
              <Grid container className={styles.partiallyProcessedError}>
                <span className={styles.partiallyProcessedErrorMessage} onClick={() => setErrorDialog(true)}>
                  {t('lists not proccessed error', {
                    amount: jobsCompletedWithErrors?.length ?? 0,
                  })}
                </span>{' '}
                <span className={styles.partiallyProcessedErrorRetry}>{t('please try again')}</span>
              </Grid>
            )}
            <Grid item>
              <Button size="sm" level="tertiary" spacing={{ margins: { sm: 'top' } }} onClick={action}>
                {currentState.buttonText}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <CustomDialog
        title={t('runs not processed')}
        open={errorDialog}
        hideX
        content={
          <Grid container>
            <Body size="lg" spacing={{ margins: { md: 'bottom' } }}>
              {t('following list not created')}
            </Body>
            <ul className={styles.errorList}>
              {ranklistJobs?.map((job) => {
                if (job?.jobErrors?.length === 0 || !job?.input || !job?.id) return null;
                return (
                  <li key={job.id} className={styles.errorRanklistName}>
                    {generateRanklistName(job.input, t)}
                  </li>
                );
              })}
            </ul>
            <CustomGrid container justifyContent="flex-end" spacing={{ margins: { lg: 'top' } }}>
              <Button size="md" level="tertiary" onClick={() => setErrorDialog(false)}>
                {t('close')}
              </Button>
              <Button
                size="md"
                level="secondary"
                onClick={handleReselectAndClose}
                spacing={{ margins: { sm: 'left' } }}
              >
                {t('reselect and close')}
              </Button>
            </CustomGrid>
          </Grid>
        }
        onClose={() => setErrorDialog(false)}
      />
    </Panel>
  );
};

RankingsProcessing.state = States;
