/* eslint-disable complexity */
import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import { ApolloQueryResult, useLazyQuery, useMutation } from '@apollo/client';
import { Grid } from '@mui/material';
import { Form, Formik, FormikHelpers } from 'formik';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import Button from 'src/components/button/button';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import FormErrorMessage from 'src/components/form-error-message/form-error-message';
import { DateField, DropdownField, RangeContainer, TextField } from 'src/components/formik-fields/formik-fields';
import { PaginationVariables } from 'src/components/table-controls/table-controls';
import { Body } from 'src/components/typography/typography';
import {
  GENERATE_RANK_LIST,
  GET_RANK_LISTS_PAGINATED,
  RANK_LIST_JOB,
} from 'src/components/usta-rankings/usta-ranking-queries';
import YearPicker from 'src/components/util-components/year-picker';
import {
  AgeRestrictionEnum,
  DivisionTypeEnum,
  ListTypeEnum,
  MatchFormatEnum,
  PlayerTypeEnum,
  RankListGenderEnum,
  RankListSortFieldsEnum,
  SortDirectionEnum,
} from 'src/graphql-types/globalRankingTypes';
import { PlayerLevelEnum } from 'src/graphql-types/globalUstaTypes';
import { getFirstOptionValue } from 'src/utils/helper/membership';
import { DivisionTypesEnum, useCircuitOptions, useRankingOptions } from 'src/utils/helper/rankings';

import { BodyRegularBold } from '@clubspark-react/clubspark-react-tools';

import * as styles from './usta-ranking-dialog.module.less';
import {
  displayDateRange,
  getAgeRestriction,
  getDateRange,
  getDirectAcceptanceItemLimit,
  getFamilyCategory,
  getGender,
  getMatchFormat,
  getNTRPLevelOptions,
  getPlayerLevel,
  getQuotaSection,
  getRunDate,
} from './usta-rankings-dialog.utils';

interface InitialValues {
  startDate: Date;
  endDate: Date;
  runDate: Date;
  circuit: PlayerTypeEnum;
  type: ListTypeEnum;
  gender: string;
  section?: string;
  ageRestriction: string;
  format: string;
  ntrpLevel: string;
  divisionType?: string;
  itemLimit?: string;
  year?: number;
}

interface RankingDialogInterface {
  setRankingDialog: Dispatch<SetStateAction<boolean>>;
  refetch?: (variables?: PaginationVariables | undefined) => Promise<ApolloQueryResult<any>>;
}

export const USTARankingDialog = ({ setRankingDialog, refetch }: RankingDialogInterface) => {
  const { t } = useTranslation();
  const [polling, setPolling] = useState(false);
  const [level, setLevel] = useState<DivisionTypeEnum>(DivisionTypeEnum.A);
  const [listType, setListType] = useState<ListTypeEnum>(ListTypeEnum.STANDING);
  const [circuit, setCircuit] = useState<PlayerTypeEnum>(PlayerTypeEnum.ADULT);
  const [divisionType, setDivisionType] = useState<DivisionTypesEnum>(DivisionTypesEnum.AGE);
  // Ensure that the state update is complete before making the eligibility refetch call.
  // Because of the complexity of this forms dynamic fields, we need to use a separate state
  // to track when the division type changes and only then trigger the query.
  const [divisionTypeChanged, setDivisionTypeChanged] = useState(false);
  const [subDivision, setSubDivision] = useState<PlayerLevelEnum>(PlayerLevelEnum.A);
  const [ageGroup, setAgeGroup] = useState<AgeRestrictionEnum>(AgeRestrictionEnum.y18);
  const [gender, setGender] = useState<RankListGenderEnum>(RankListGenderEnum.M);
  const [dates, setDates] = useState<Record<'startDate' | 'endDate' | 'runDate', Date>>({
    startDate: new Date(),
    endDate: new Date(),
    runDate: new Date(),
  });

  const [generateRankList, { loading: generatingRankList, error }] = useMutation(GENERATE_RANK_LIST, {
    client: meshGatewayClient,
  });

  const [checkRankListStatus, { data, stopPolling, error: rankListError }] = useLazyQuery(RANK_LIST_JOB, {
    pollInterval: 3000,
    client: meshGatewayClient,
  });

  // This query is used to fetch the available year end final rankings to base the date that the eligibility
  // list should be generated for. This is only used for the eligibility list type.
  const [checkEligibilityListRange, { data: finalRankingLists, refetch: refetchFinalRankingLists }] = useLazyQuery(
    GET_RANK_LISTS_PAGINATED,
    {
      client: meshGatewayClient,
      variables: {
        ranklistFilter: {
          visible: true,
          listType: ListTypeEnum.YEAR_END,
          playerType: PlayerTypeEnum.WHEELCHAIR,
          matchFormat: MatchFormatEnum.SINGLES,
          ...getPlayerLevel({
            circuit: PlayerTypeEnum.WHEELCHAIR,
            divisionType,
            playerLevel: level,
            ageRestriction: ageGroup,
            subDivision,
          }),
          ...getAgeRestriction({
            playerType: PlayerTypeEnum.WHEELCHAIR,
            divisionType,
            ageRestriction: ageGroup,
          }),
        },
        pageArgs: { limit: 10, skip: 0 },
        sort: {
          field: RankListSortFieldsEnum.CREATED_AT,
          direction: SortDirectionEnum.ASC,
        },
      },
    },
  );

  const eligibilityListDateRange = useMemo(() => {
    const lists = finalRankingLists?.ranklistsPaginated?.items ? [...finalRankingLists.ranklistsPaginated.items] : [];
    lists?.sort((a, b) => new Date(a.dateRange.end).getFullYear() - new Date(b.dateRange.end).getFullYear());
    if (lists?.length) {
      return {
        minYear: new Date(lists[0].dateRange?.end).getFullYear(),
        maxYear: new Date(lists[lists.length - 1].dateRange?.end).getFullYear(),
      };
    }
  }, [finalRankingLists]);

  useEffect(() => {
    const rankListJob = data?.rankListJob;
    const hasErrors = rankListJob?.jobErrors?.length !== 0;

    if (!rankListJob?.jobComplete) return;

    if (!rankListError && !hasErrors) {
      stopPolling();
      setPolling(false);
      setRankingDialog(false);
      refetch?.();
    } else {
      stopPolling();
      setPolling(false);
    }
  }, [data, rankListError, refetch, setRankingDialog, stopPolling]);

  const circuitOptions = useCircuitOptions();

  const [year, setYear] = useState<number>(eligibilityListDateRange?.maxYear);

  useEffect(() => {
    setDivisionType(getFirstOptionValue(divisionTypeOptions) as DivisionTypesEnum);
    setGender(getFirstOptionValue(genderOptions) as RankListGenderEnum);
  }, [circuit]);

  const {
    divisionTypeOptions = [],
    genderOptions,
    formatOptions,
    ageGroupOptions,
    typeOptions,
    subDivisionOptions,
    NTRPLevelOptions = [],
    sectionOptions = [],
  } = useRankingOptions({ circuit, divisionType, gender, listType }) as any;

  const applicableLevels = useMemo(() => getNTRPLevelOptions(ageGroup), [ageGroup]);

  const initialValues: InitialValues = useMemo(
    () => ({
      startDate: dates?.startDate ?? new Date(),
      endDate: dates?.endDate ?? new Date(),
      runDate: dates?.runDate ?? new Date(),
      circuit,
      type: listType,
      gender: getFirstOptionValue(genderOptions),
      ageRestriction: getFirstOptionValue(ageGroupOptions),
      format: getFirstOptionValue(formatOptions),
      subDivision: getFirstOptionValue(subDivisionOptions),
      section: getFirstOptionValue(sectionOptions),
      divisionType,
      ntrpLevel: NTRPLevelOptions?.length ? getFirstOptionValue(NTRPLevelOptions) : '',
      itemLimit: '',
    }),
    [circuit, divisionType, genderOptions],
  ) as any;

  function isItemLimitError(type: ListTypeEnum, itemLimit: string): boolean {
    return !!(type === ListTypeEnum.DIRECT_ACCEPTANCE && !itemLimit);
  }

  const handleSetDivisionType = (value: DivisionTypeEnum) => {
    if (circuit === PlayerTypeEnum.WHEELCHAIR) {
      setLevel(value);
    }
    setDivisionType(value as unknown as DivisionTypesEnum);
    setDivisionTypeChanged(true);
  };

  useEffect(() => {
    if (divisionTypeChanged) {
      refetchFinalRankingLists();
      setDivisionTypeChanged(false);
    }
  }, [divisionTypeChanged, refetchFinalRankingLists]);

  const handleChangeListType = (value: ListTypeEnum) => {
    setListType(value);
    if (value === ListTypeEnum.ELIGIBILITY) {
      checkEligibilityListRange();
    }
  };

  const handleSetYear = (newYear: number) => {
    setYear(newYear);
  };

  async function handleSubmit(values: any, actions: FormikHelpers<any>) {
    const {
      gender,
      format,
      startDate,
      endDate,
      runDate,
      type,
      ageRestriction,
      circuit,
      divisionType,
      ntrpLevel,
      section,
      itemLimit,
      subDivision,
    } = values;

    if (isItemLimitError(type, itemLimit)) {
      actions.setFieldError('itemLimit', t('is required', { type: 'amount of players' }));
      return;
    }

    if (type === ListTypeEnum.DIRECT_ACCEPTANCE && !itemLimit.match(/^[0-9]+$/)) {
      actions.setFieldError('itemLimit', 'Amount of players must be a number');
      return;
    }

    const input = {
      listType: type,
      playerType: circuit,
      ...getGender(circuit, gender, divisionType),
      ...getMatchFormat(circuit, type, format, gender),
      ...getPlayerLevel({
        circuit,
        divisionType,
        playerLevel: ntrpLevel,
        ageRestriction,
        subDivision,
      }),
      ...getFamilyCategory({ circuit, divisionType }),
      ...getAgeRestriction({
        playerType: circuit,
        divisionType,
        ageRestriction,
      }),
      ...getQuotaSection({ playerType: circuit, listType: type, section }),
      ...getDirectAcceptanceItemLimit({ listType: type, itemLimit }),
      ...getDateRange({ listType: type, startDate, endDate }),
      ...getRunDate({ listType: type, runDate }),
      year: year || eligibilityListDateRange?.maxYear,
    };

    const response = await generateRankList({ variables: { input } });

    checkRankListStatus({ variables: { id: response?.data?.generateRankList?.id } });

    // we don't want to bother polling the rank list job if the initial rank list request failed validation
    if (!response?.data?.generateRankList?.jobComplete && !response?.data?.generateRankList?.jobErrors) {
      setPolling(true);
    }
  }

  const hasFormatField = (circuit: PlayerTypeEnum, type: ListTypeEnum): boolean =>
    circuit === PlayerTypeEnum.JUNIOR ? type === ListTypeEnum.SEEDING || type === ListTypeEnum.YEAR_END : true;

  const hasAgeGroupField = (circuit: PlayerTypeEnum, divisionType: DivisionTypesEnum): boolean =>
    circuit === PlayerTypeEnum.WHEELCHAIR ? divisionType === DivisionTypesEnum.AGE : true;

  const hasSubDivisionField = (
    circuit: PlayerTypeEnum,
    gender: string,
    ageGroup: string,
    divisionType: DivisionTypeEnum,
  ): boolean =>
    circuit === PlayerTypeEnum.WHEELCHAIR &&
    gender === RankListGenderEnum.C &&
    ageGroup === AgeRestrictionEnum.y18 &&
    divisionType === DivisionTypeEnum.AGE;

  const hasGenderField = (circuit: PlayerTypeEnum): boolean => circuit !== PlayerTypeEnum.FAMILY;

  const hasSectionField = (type: ListTypeEnum): boolean => type === ListTypeEnum.QUOTA;

  const hasYearField = (listType: ListTypeEnum): boolean => listType === ListTypeEnum.ELIGIBILITY;

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
      {({ values }) => (
        <Form>
          <Grid container direction="column">
            <Body size="md">{t('new ranking list explanation')}</Body>
            <BodyRegularBold spacing={{ margins: { xxs: 'bottom', md: 'top' } }}>
              {t('player category')}
            </BodyRegularBold>
            <DropdownField name="circuit" options={circuitOptions} onSelect={(o) => setCircuit(o.value)} />
            {values.circuit !== PlayerTypeEnum.JUNIOR && (
              <>
                <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                  {t('division type')}
                </BodyRegularBold>
                <DropdownField
                  name="divisionType"
                  options={divisionTypeOptions}
                  onSelect={(o) => handleSetDivisionType(o.value as DivisionTypeEnum)}
                />
              </>
            )}
            <Grid container spacing={3}>
              <Grid item xs>
                <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>{t('list type')}</BodyRegularBold>
                <DropdownField name="type" options={typeOptions} onSelect={(o) => handleChangeListType(o.value)} />
              </Grid>
              {hasYearField(values.type) && (
                <Grid item xs>
                  <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom', sm: 'left' } }}>
                    {t('year')}
                  </BodyRegularBold>
                  <YearPicker
                    {...eligibilityListDateRange}
                    selectedYear={year}
                    onChange={(newYear) => handleSetYear(newYear)}
                  />
                </Grid>
              )}
            </Grid>
            {displayDateRange(values.type) && (
              <Grid container spacing={4}>
                <Grid item xs>
                  <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                    {t('start date')}
                  </BodyRegularBold>
                  <RangeContainer noMargin>
                    <DateField
                      name="startDate"
                      onChange={(date) => setDates({ ...dates, startDate: date })}
                      datePickerProps={{
                        disabled: false,
                        selected: new Date(values.startDate),
                        popperPlacement: 'top',
                        maxDate: new Date(),
                      }}
                    />
                  </RangeContainer>
                </Grid>
                <Grid item xs>
                  <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>{t('end date')}</BodyRegularBold>
                  <RangeContainer noMargin>
                    <DateField
                      name="endDate"
                      onChange={(date) => setDates({ ...dates, endDate: date })}
                      datePickerProps={{
                        disabled: false,
                        selected: new Date(values.endDate),
                        popperPlacement: 'top',
                        minDate: new Date(values.startDate),
                      }}
                    />
                  </RangeContainer>
                </Grid>
              </Grid>
            )}
            {values.type === ListTypeEnum.DIRECT_ACCEPTANCE && (
              <Grid item xs>
                <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                  {t('amount of players')}
                </BodyRegularBold>
                <TextField
                  classes={{ root: styles.directAcceptanceInput }}
                  name="itemLimit"
                  InputProps={{ className: styles.directAcceptanceInput }}
                  type="tel"
                  inputProps={{ maxLength: 6 }}
                />
              </Grid>
            )}
            {values.type === ListTypeEnum.L2_QUALIFIER && (
              <Grid item xs>
                <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>{t('run date')}</BodyRegularBold>
                <RangeContainer noMargin>
                  <DateField
                    name="runDate"
                    onChange={(date) => setDates({ ...dates, runDate: date })}
                    datePickerProps={{
                      disabled: false,
                      selected: new Date(values.runDate),
                      popperPlacement: 'top',
                      maxDate: new Date(),
                    }}
                  />
                </RangeContainer>
              </Grid>
            )}
            {hasSectionField(values.type) && (
              <Grid item xs>
                <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>{t('section')}</BodyRegularBold>
                <DropdownField name="section" options={sectionOptions} />
              </Grid>
            )}
            <Grid container spacing={3}>
              {hasGenderField(values.circuit) && (
                <Grid item xs>
                  <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>{t('gender')} *</BodyRegularBold>
                  <DropdownField name="gender" options={genderOptions} onSelect={(o) => setGender(o.value)} />
                </Grid>
              )}
              {hasAgeGroupField(values.circuit, values.divisionType as DivisionTypesEnum) && (
                <Grid item xs>
                  <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                    {t('age group')}
                  </BodyRegularBold>
                  <DropdownField
                    name="ageRestriction"
                    options={ageGroupOptions}
                    onSelect={(o) => setAgeGroup(o.value)}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container>
              {hasSubDivisionField(
                values.circuit,
                values.gender,
                values.ageRestriction,
                values.divisionType as DivisionTypeEnum,
              ) && (
                <Grid item xs>
                  <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>
                    {t('sub division')}
                  </BodyRegularBold>
                  <DropdownField
                    name="subDivision"
                    options={subDivisionOptions}
                    onSelect={(o) => setSubDivision(o.value)}
                  />
                </Grid>
              )}
            </Grid>
            <Grid container>
              {hasFormatField(values.circuit, values.type) && (
                <Grid item xs>
                  <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>{t('format')}</BodyRegularBold>
                  <DropdownField name="format" options={formatOptions} />
                </Grid>
              )}
            </Grid>
            {values.divisionType === DivisionTypesEnum.NTRP && circuit === PlayerTypeEnum.ADULT && (
              <>
                <BodyRegularBold spacing={{ margins: { md: 'top', xxs: 'bottom' } }}>{t('ntrp level')}</BodyRegularBold>
                <DropdownField name="ntrpLevel" options={applicableLevels} />
              </>
            )}
          </Grid>
          <CustomGrid container justifyContent="flex-end" spacing={{ margins: { lg: 'top' } }}>
            <Button onClick={() => setRankingDialog(false)} type="button" level="tertiary">
              {t('cancel')}
            </Button>
            <Button type="submit" spacing={{ margins: { sm: 'left' } }} loading={generatingRankList || polling}>
              {t('create ranking list')}
            </Button>
          </CustomGrid>
          <CustomGrid container justifyContent="flex-end">
            {error && <FormErrorMessage spacing={{ margins: { md: 'top' } }} message={t('generate ranklist error')} />}
            {!error && data?.rankListJob?.jobErrors && (
              <FormErrorMessage spacing={{ margins: { md: 'top' } }} message={data?.rankListJob?.jobErrors?.[0]} />
            )}
          </CustomGrid>
        </Form>
      )}
    </Formik>
  );
};
