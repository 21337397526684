import React, { useEffect, useState } from 'react';

import { Grid, IconButton, MenuItem } from '@material-ui/core';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Dropdown, { Option } from 'src/components/dropdown/dropdown';

interface YearPickerProps {
  minYear?: number;
  maxYear?: number;
  selectedYear?: number;
  onChange?: (year: number) => void;
}

const YearPicker: React.FC<YearPickerProps> = ({
  minYear = 2020,
  maxYear = new Date().getFullYear(),
  selectedYear,
  onChange,
}) => {
  const [year, setYear] = useState<number>(selectedYear || maxYear);

  const handleYearChange = (newYear: number) => {
    if (newYear >= minYear && newYear <= maxYear) {
      setYear(newYear);
      onChange?.(newYear);
    }
  };

  const incrementYear = () => {
    if (year < maxYear) handleYearChange(year + 1);
  };

  const decrementYear = () => {
    if (year > minYear) handleYearChange(year - 1);
  };

  useEffect(() => {
    if (selectedYear) {
      setYear(selectedYear);
    }
  }, [selectedYear]);

  const generateYears = () => {
    const years: Option[] = [];
    for (let i = maxYear; i >= minYear; i--) {
      years.push({ label: `${i}`, value: `${i}` });
    }
    return years;
  };

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item>
        <IconButton onClick={decrementYear} disabled={year <= minYear}>
          <FaChevronLeft size={15} />
        </IconButton>
      </Grid>

      <Grid item>
        <Dropdown
          options={generateYears()}
          onSelect={({ value }) => {
            handleYearChange(Number(value));
          }}
          selected={selectedYear}
          data-testid="year-select"
        />
      </Grid>

      <Grid item>
        <IconButton onClick={incrementYear} disabled={year >= maxYear}>
          <FaChevronRight size={15} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default YearPicker;
