import React from 'react';

import { useTranslation } from 'react-i18next';
import Button from 'src/components/button/button';
import { CustomGrid } from 'src/components/custom-grid/custom-grid';
import { Body } from 'src/components/typography/typography';

import * as styles from './usta-rankings-full-selection.module.less';

enum State {
  ACTIVE,
  INACTIVE,
}

interface FullSelectionProps {
  selected: number;
  total: number;
  handleClick: () => void;
  state: State;
}

export const FullSelection = ({ selected, total, handleClick, state }: FullSelectionProps) => {
  const { t } = useTranslation();

  const STATES_MAP: Record<State, Record<'text' | 'actionText', string>> = {
    [State.INACTIVE]: {
      text: t('all page items selected', { amount: selected }),
      actionText: t('select all items', { total }),
    },
    [State.ACTIVE]: {
      text: t('all items selected', { amount: total }),
      actionText: t('clear all selections'),
    },
  };

  const currentState = STATES_MAP[state];

  return (
    <CustomGrid
      container
      justifyContent="center"
      alignItems="center"
      className={styles.fullSelection}
      spacing={{ margins: { xs: 'bottom' } }}
    >
      <Body size="md">{currentState.text}</Body>
      <Button linkStyle onClick={handleClick}>
        {currentState.actionText}
      </Button>
    </CustomGrid>
  );
};

FullSelection.state = State;
